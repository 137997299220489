import { Box, Flex, Progress } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { formatToKRW, formatToUSD } from '@/utils/format-currency';

import { SettlementFailureFeedback } from './settlement-failure-feedback';

const MAXIMUM_MONEY = 100000;
const MAXIMUM_MONEY_USD = 100;

type UnsettlementRevenueProps = {
  isFormComplete: boolean;
  isLoading: boolean;
  revenue: number;
  feedback: string | null;
  providerId?: string;
  isDomestic: boolean;
  onOpenSettlementInputModal: () => void;
};

export function UnsettlementRevenue({
  isFormComplete,
  isLoading,
  revenue,
  feedback,
  providerId,
  isDomestic,
  onOpenSettlementInputModal,
}: UnsettlementRevenueProps) {
  const { t } = useTranslation('settlePage');
  return (
    <Box
      w="full"
      border="1px solid"
      borderColor="grey.200"
      borderRadius={10}
      p={6}
    >
      <Flex flexDirection="column" mb={3}>
        <TDS.Typo as="h2" size="sm" weight="normal">
          {t('unsettlement-revenue')}
        </TDS.Typo>
        <Flex w="full" alignItems="center" justifyContent="space-between">
          <TDS.Typo as="h3" size="2xl" weight="medium">
            {isDomestic ? formatToKRW(revenue) : formatToUSD(revenue)}
          </TDS.Typo>
          {providerId && (
            <SettlementFailureFeedback
              isFormComplete={isFormComplete}
              isLoading={isLoading}
              feedback={feedback}
              onOpenSettlementInputModal={onOpenSettlementInputModal}
            />
          )}
        </Flex>
      </Flex>
      <RevenueProgress
        money={revenue}
        maxMoney={isDomestic ? MAXIMUM_MONEY : MAXIMUM_MONEY_USD}
        isDomestic={isDomestic}
      />
    </Box>
  );
}

type RevenueProgressProps = {
  money: number;
  maxMoney: number;
  isDomestic: boolean;
};

function RevenueProgress({
  money,
  maxMoney,
  isDomestic,
}: RevenueProgressProps) {
  const minMoney = 0;

  return (
    <Flex flexDirection="column" rowGap={1}>
      <Box w="full" h={4} borderRadius={999} overflow="hidden">
        <Progress size="lg" value={(money / maxMoney) * 100} />
      </Box>
      <Flex justifyContent="space-between">
        <TDS.Typo size="xs" weight="normal" color="grey.600">
          {isDomestic ? formatToKRW(minMoney) : formatToUSD(minMoney)}
        </TDS.Typo>
        <TDS.Typo size="xs" weight="normal" color="grey.600">
          {isDomestic ? formatToKRW(maxMoney) : formatToUSD(maxMoney)}
        </TDS.Typo>
      </Flex>
    </Flex>
  );
}
