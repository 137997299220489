import { useReactToPrint } from 'react-to-print';

import { requestGetDataProviderDetailProfits } from '@/api/payday';
import { GetDataProviderDetailProfitsResponseDTO } from '@/api/types/payday';
import LogoUrl from '@/assets/images/logo.svg';
import { ScreenLoading } from '@/components/screen-loading';
import {
  Modal,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Thead,
  ModalHeader,
  ModalBody,
  useToast,
} from '@/components/ui';
import { isSuccess } from '@/utils/guard';

import './payment-summer-styles.css';

export function PaymentSummaryModal({
  isOpen,
  onClose,
  mkpProfitId,
}: {
  isOpen: boolean;
  onClose: () => void;
  mkpProfitId: string;
}) {
  const initialRef = useRef<HTMLButtonElement>(null);
  const paymentRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<GetDataProviderDetailProfitsResponseDTO>();
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const providerProfitsRes =
        await requestGetDataProviderDetailProfits(mkpProfitId);

      if (isSuccess(providerProfitsRes)) {
        setData(providerProfitsRes.data);
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: providerProfitsRes.error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      }
    })();
  }, [mkpProfitId]);

  const reactToPrintFn = useReactToPrint({
    contentRef: paymentRef,
  });

  useEffect(() => {
    if (!paymentRef.current || !data) return;
    reactToPrintFn();
    onClose();
  }, [data, paymentRef]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      size="3xl"
    >
      <ScreenLoading show={isOpen} />
      <Box className="opacity-0">
        <Box ref={paymentRef}>
          <ModalHeader
            fontSize="2xl"
            fontWeight="bold"
            paddingY={4}
            paddingX={0}
          >
            <Box className="flex justify-end">
              <img src={LogoUrl} alt="" className="w-30" />
            </Box>
            <h1>Payment Summary</h1>
          </ModalHeader>
          <ModalBody paddingY={2} paddingX={0}>
            {data && (
              <>
                <PaymentSummaryTable data={data} />
                <PaymentDetailTable data={data} />
              </>
            )}
          </ModalBody>
        </Box>
      </Box>
    </Modal>
  );
}

const PaymentSummaryTable = ({
  data,
}: {
  data: GetDataProviderDetailProfitsResponseDTO;
}) => {
  const companyData = [
    { label: 'Name', value: data.company_info.name },
    { label: 'Address', value: data.company_info.address },
    { label: 'Email', value: data.company_info.email },
    { label: 'Contact', value: data.company_info.contact },
  ];
  const voiceActorData = [
    { label: 'Name', value: data.voice_actor_info.name },
    { label: 'Address', value: data.voice_actor_info.address },
    { label: 'Email', value: data.voice_actor_info.email },
    { label: 'Contact', value: data.voice_actor_info.contact },
  ];
  const paymentDescriptionLabel = [
    {
      label: 'Description',
      key: 'description',
    },
    {
      label: 'Value',
      key: 'value',
    },
    {
      label: 'Running Total',
      key: 'runningTotal',
    },
  ] as const;
  const paymentDescriptionData: Record<
    (typeof paymentDescriptionLabel)[number]['key'],
    string
  >[] = data.payment_description.description_row.map(rowData => ({
    description: rowData.description,
    value: rowData.earnings,
    runningTotal: rowData.cumulative_earnings,
  }));
  const paymentData = [
    { label: 'SUBTOTAL', value: data.payment_description.subtotal },
    { label: 'Tax', value: data.payment_description.tax },
    { label: 'TOTAL', value: data.payment_description.total },
    { label: 'Payment Due', value: data.payment_description.payment_due },
  ];

  return (
    <section className="break-after-page report">
      <Box className="max-w-4xl mx-auto rounded-lg">
        <Box className="grid grid-cols-2 gap-6 mb-8">
          <Box>
            <h2 className="text-xl font-semibold mb-4">Company</h2>
            <Table variant="simple" size="md">
              <Tbody>
                {companyData.map(data => (
                  <Tr key={data.label}>
                    <Th
                      textTransform="none"
                      className="bg-gray-100 border-gray-300! border p-2! w-25"
                    >
                      {data.label}
                    </Th>
                    <Td className="border-gray-300! border p-2! break-all">
                      {data.value}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <Box>
            <h2 className="text-xl font-semibold mb-4">Voice Actor</h2>
            <Table variant="simple" size="md">
              <Tbody>
                {voiceActorData.map(data => (
                  <Tr key={data.label}>
                    <Th
                      textTransform="none"
                      className="bg-gray-100 border-gray-300! border p-2! w-25"
                    >
                      {data.label}
                    </Th>
                    <Td className="border-gray-300! border p-2! break-all">
                      {data.value}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Box className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Payment Description</h2>
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                {paymentDescriptionLabel.map(({ label, key }) => (
                  <Th
                    key={key}
                    textTransform="none"
                    className="bg-gray-100 border-gray-300! border p-2!"
                  >
                    {label}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {paymentDescriptionData.map((data, i) => (
                <Tr key={i}>
                  <Td className="border-gray-300! border p-2! break-all">
                    {data.description}
                  </Td>
                  <Td className="border-gray-300! border p-2! break-all">
                    {data.value}
                  </Td>
                  <Td className="border-gray-300! border p-2! break-all">
                    {data.runningTotal}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Box className="max-w-xs ml-auto">
          <Table variant="simple" size="md">
            <Tbody>
              {paymentData.map(data => (
                <Tr key={data.label}>
                  <Th
                    textTransform="none"
                    className="bg-gray-100 border-gray-300! border p-2! w-25"
                  >
                    {data.label}
                  </Th>
                  <Td className="border-gray-300! border p-2! break-all">
                    {data.value}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </section>
  );
};

const PaymentDetailTable = ({
  data,
}: {
  data: GetDataProviderDetailProfitsResponseDTO;
}) => {
  const detailData = [
    { label: 'Talent’s full name', value: data.payment_detail.full_name },
    {
      label: 'Business name',
      value: data.payment_detail.bank_name,
    },
    { label: 'Address', value: data.payment_detail.address },
    { label: 'Phone no.', value: data.payment_detail.phone_no },
    { label: 'Bank name', value: data.payment_detail.bank_name },
    { label: 'Bank account no.', value: data.payment_detail.bank_account_info },
    { label: 'Routing no.', value: data.payment_detail.routing_no },
    {
      label: 'Account type',
      value: data.payment_detail.account_type,
    },
    { label: 'Bank address', value: data.payment_detail.bank_address },
    { label: 'Country', value: data.payment_detail.bank_country },
    { label: 'SWIFT / BIC code', value: data.payment_detail.swift_bic_code },
    { label: 'IBAN', value: data.payment_detail.iban },
    { label: 'Notes', value: data.payment_detail.notes },
  ];

  return (
    <section className="break-after-page report">
      <Box className="max-w-4xl mx-auto rounded-lg">
        <Box>
          <h2 className="text-xl font-semibold mb-4">Payment Details:</h2>
          <Table variant="simple" size="md">
            <Tbody>
              {detailData.map(data => (
                <Tr key={data.label}>
                  <Th
                    textTransform="none"
                    className="bg-gray-100 border-gray-300! border p-2! w-50 whitespace-pre-wrap"
                  >
                    {data.label}
                  </Th>
                  <Td className="border-gray-300! border p-2! break-all">
                    {data.value}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </section>
  );
};
