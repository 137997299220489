import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { Role } from '@/types/role';
import type { User } from '@/types/user';

export type UserSlice = {
  isAuthorized: boolean;
  user?: Partial<User>;
  isSigned: boolean;
  roles: Role[];
  residence: string;
  setUser: (user: Partial<User>) => void;
  clearUser: () => void;
  setIsSigned: (isSigned: boolean) => void;
  setRoles: (roles: Role[]) => void;
  setResidence: (residence: string) => void;
};

export const useUserStore = create<UserSlice>()(
  devtools(
    set => ({
      user: undefined,
      isAuthorized: false,
      isSigned: false,
      roles: [],
      residence: '',
      setUser: (user: Partial<User>) => set({ user, isAuthorized: true }),
      clearUser: () => set({ user: undefined, isAuthorized: false }),
      setIsSigned: (isSigned: boolean) => set({ isSigned }),
      setRoles: (roles: Role[]) => set({ roles }),
      setResidence: (residence: string) => set({ residence }),
    }),
    { name: 'UserStore' },
  ),
);
