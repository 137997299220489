import { useTranslation } from 'react-i18next';

import { GetDataProviderProfitsResponseDTO } from '@/api/types/payday';
import {
  Flex,
  Skeleton,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/components/ui';
import { TDS } from '@/libs/chakra';
import { CharacterProfit, ProfitRow } from '@/types/profits';
import { cn } from '@/utils';
import { formatToKRW, formatToUSD } from '@/utils/format-currency';
import { getPreviousMonthStartAndEnd, dayjs } from '@/utils/time';

import { SettlementPagination } from './settlement-pagination';
import { SettlementRevenue } from './settlement-revenue';

export type Period = {
  startDate: string;
  endDate: string;
};

type SettlementTableProps = {
  profit: GetDataProviderProfitsResponseDTO;
  profitRows?: ProfitRow[];
  pageIndex: number;
  pageLimit: number;
  onClickPrev: (index: number) => void;
  onClickNext: (index: number, quotient: number) => void;
  onClickPage: (num: number) => void;
  onClickMonthRevenue: (
    date: Period,
    item: CharacterProfit[],
    totalRevenue: number,
    isDomestic: boolean,
  ) => void;
  onClickSettlementRevenue: (mkpProfitId: string) => void;
};

export function SettlementTable({
  profit,
  profitRows,
  pageIndex,
  pageLimit,
  onClickPrev,
  onClickNext,
  onClickPage,
  onClickMonthRevenue,
  onClickSettlementRevenue,
}: SettlementTableProps) {
  return (
    <>
      <Flex flexDirection="column" rowGap={3}>
        <SettledTable
          profitRows={profitRows}
          onClickMonthRevenue={onClickMonthRevenue}
          onClickSettlementRevenue={onClickSettlementRevenue}
          pageLimit={pageLimit}
        />
        <SettlementPagination
          index={pageIndex}
          total={profit.profit_rows.total}
          size={pageLimit}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          onClickPage={onClickPage}
        />
      </Flex>
    </>
  );
}

function SettledTable({
  profitRows: data,
  pageLimit,
  onClickMonthRevenue,
  onClickSettlementRevenue,
}: {
  profitRows?: ProfitRow[];
  pageLimit: number;
  onClickMonthRevenue: (
    date: Period,
    item: CharacterProfit[],
    totalRevenue: number,
    isDomestic: boolean,
  ) => void;
  onClickSettlementRevenue: (mkpProfitId: string) => void;
}) {
  const { t, i18n } = useTranslation('settlePage');
  const theadKeys = [
    t('table.date'),
    t('table.previous-month-cumulative-revenue'),
    t('table.current-month-revenue'),
    t('table.current-month-cumulative-revenue'),
    t('table.current-month-settlement-revenue'),
  ] as const;

  return (
    <TableContainer borderRadius={10}>
      <Table className="table-fixed">
        <Thead bgColor="grey.700">
          <Tr>
            {theadKeys.map((key, i) => (
              <Th
                isNumeric={i !== 0}
                key={key}
                p="11px 24px"
                whiteSpace={'pre-line'}
              >
                <TDS.Typo size="xs" weight="bold" color="white">
                  {key}
                </TDS.Typo>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody bgColor="white">
          {(() => {
            if (!data)
              return (
                <>
                  {Array.from({ length: pageLimit }).map((_, i) => (
                    <Tr key={`SettlementTable_Tbody_Skeleton_${i}`}>
                      <TdSkeleton />
                    </Tr>
                  ))}
                </>
              );

            return (
              <>
                {data.map((item, i) => {
                  const date = dayjs(item.target_date);

                  const year = date.year();
                  const month = date.month() + 1;

                  const previousDate = getPreviousMonthStartAndEnd({
                    date,
                    language: i18n.language,
                    format: {
                      year: false,
                    },
                    separator: i18n.language === 'ko' ? '' : '/',
                  });

                  const startDate = previousDate.startDate;
                  const endDate = previousDate.endDate;

                  const isDomestic = item.residence_type === 'domestic';

                  return (
                    <Tr key={`SettlementTable_Tbody_${i}`}>
                      <Td p="8.5px 24px">
                        <Flex flexDirection="column">
                          <TDS.Typo size="sm" weight="medium" color="black">
                            {i18n.language === 'ko'
                              ? `${year}년 ${month}월`
                              : `${`${month}`.padStart(2, '0')}/${year}`}
                          </TDS.Typo>
                          <TDS.Typo size="xs" weight="normal" color="grey.700">
                            ({startDate} {i18n.language === 'ko' ? '~' : '-'}{' '}
                            {endDate})
                          </TDS.Typo>
                        </Flex>
                      </Td>
                      <Td isNumeric>
                        <TDS.Typo size="sm" color="grey.700">
                          {isDomestic
                            ? formatToKRW(item.current_payable_amount)
                            : formatToUSD(item.current_payable_amount_usd)}
                        </TDS.Typo>
                      </Td>
                      <Td isNumeric p="8.5px 24px">
                        <SettlementRevenue
                          size="sm"
                          color="grey.700"
                          revenue={
                            isDomestic
                              ? item.monthly_profit_amount
                              : item.monthly_profit_amount_usd
                          }
                          isDomestic={isDomestic}
                          onClickDetail={() =>
                            onClickMonthRevenue(
                              previousDate,
                              item.character_profits,
                              isDomestic
                                ? item.monthly_profit_amount
                                : item.monthly_profit_amount_usd,
                              item.residence_type === 'domestic',
                            )
                          }
                          icon={
                            <i
                              className={cn(
                                'i-t-detail text-6 ml-1 color-black',
                              )}
                            />
                          }
                        />
                      </Td>
                      <Td isNumeric>
                        <TDS.Typo size="sm" color="grey.700">
                          {isDomestic
                            ? formatToKRW(item.current_profit_amount)
                            : formatToUSD(item.current_profit_amount_usd)}
                        </TDS.Typo>
                      </Td>
                      <Td isNumeric>
                        <TDS.Typo size="sm" color="grey.700">
                          <SettlementRevenue
                            size="sm"
                            color="grey.700"
                            revenue={
                              isDomestic
                                ? item.withdrawal_amount
                                : item.withdrawal_amount_usd
                            }
                            isDomestic={isDomestic}
                            onClickDetail={() =>
                              onClickSettlementRevenue(item.mkp_profit)
                            }
                            disabled={!item.is_withdrawal_target}
                            icon={
                              <i
                                className={cn(
                                  'i-t-print text-6 ml-1 color-black',
                                  !item.is_withdrawal_target &&
                                    'opacity-40 cursor-not-allowed',
                                )}
                              />
                            }
                          />
                        </TDS.Typo>
                      </Td>
                    </Tr>
                  );
                })}
              </>
            );
          })()}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function TdSkeleton() {
  return (
    <>
      <Td p="8.5px 24px">
        <Flex flexDirection="column">
          <Skeleton h={21} w={74} rounded={3} />
          <Skeleton h={18} w={130} rounded={3} />
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
      <Td p="8.5px 24px">
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
    </>
  );
}
