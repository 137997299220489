import { useTranslation } from 'react-i18next';

import { Title } from '@/components/title';
import { SETTLEMENT_GUIDE_LINK } from '@/constants/external-link';
import { Flex, TDS } from '@/libs/chakra';

export function SettlementTitle({ isDomestic }: { isDomestic: boolean }) {
  const { t } = useTranslation('settlePage');
  return (
    <>
      <Flex align="center" gap={2}>
        <Title className="mb-0">{t('title')}</Title>
        {isDomestic && (
          <TDS.Button
            as="a"
            href={SETTLEMENT_GUIDE_LINK}
            target="_blank"
            size="sm"
            variant="outline"
            colorScheme="grey"
          >
            {t('guide')}
          </TDS.Button>
        )}
      </Flex>
    </>
  );
}
