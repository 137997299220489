type formatCurrencyOption = Intl.NumberFormatOptions & {
  locale?: string | string[];
};

export function formatToKRW(amount: number) {
  return (
    formatCurrency(amount, {
      locale: 'ko-KR',
      style: 'currency',
      currency: 'KRW',
    }).slice(1) + '원'
  );
}

export function formatToUSD(amount: number) {
  return formatCurrency(amount, {
    locale: 'en-US',
    style: 'currency',
    currency: 'USD',
  });
}

export function formatCurrency(amount: number, option: formatCurrencyOption) {
  const { locale } = option;

  const formatter = new Intl.NumberFormat(locale, {
    ...option,
  });

  return formatter.format(amount);
}
