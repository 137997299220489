import 'dayjs/locale/en';
import 'dayjs/locale/ko';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import * as en from './languages/en-US';
import * as ko from './languages/ko-KR';

const resources = {
  en: en,
  ko: ko,
} as const;
type SupportedLanguage = keyof typeof resources;
const LANGUAGES = { EN: 'en', KO: 'ko' } as const satisfies Record<
  string,
  SupportedLanguage
>;

const getBrowserLanguage = () => {
  const lang = navigator.language.split('-')[0];
  return Object.values(LANGUAGES).includes(lang as SupportedLanguage)
    ? lang
    : 'en';
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: Object.values(LANGUAGES),
    debug: import.meta.env.DEV,
    fallbackLng: getBrowserLanguage(),
    resources: {
      en,
      ko,
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'querystring',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],
      lookupQuerystring: 'lang',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    load: 'languageOnly',
  });

export default i18n;
