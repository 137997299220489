import { useShallow } from 'zustand/react/shallow';

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  requestGetBankAccount,
  requestGetBankAccountBankbookImage,
  requestGetBankAccountIdCardImage,
  requestPartnerGetDataProviderInfo,
  requestGetDataProviderProfits,
  requestGetDataProviderProfitsTotal,
} from '@/api/payday';
import {
  GetPartnerDataProviderInfoResponseDTO,
  GetDataProviderProfitsResponseDTO,
} from '@/api/types/payday';
import { Flex, Skeleton, useDisclosure, useToast } from '@/components/ui';
import { useSettleStore } from '@/stores/settle';
import { useUserStore } from '@/stores/user';
import { CharacterProfit, ProfitRow } from '@/types/profits';
import { isFailed, isSuccess } from '@/utils/guard';
import { getPreviousMonthStartAndEnd } from '@/utils/time';

import { PaymentSummaryModal } from './components/payment-summary';
import { SettlementDetailModal } from './components/settlement-detail-modal';
import { SettlementInputModal } from './components/settlement-input-modal';
import { MAX_INDEX } from './components/settlement-pagination';
import { SettlementStatus } from './components/settlement-status';
import { Period, SettlementTable } from './components/settlement-table';
import { SettlementTitle } from './components/settlement-title';
import { SettlementTotalRevenueBoxContainer } from './components/settlement-total-revenue-box-container';
import { UnsettlementRevenue } from './components/unsettlement-revenue';
import { parseAddress } from './utils/parse-address';

export default function SettlementPage() {
  const { t, i18n } = useTranslation('settlePage');

  const pageLimit = 12;
  const settlementInputModal = useDisclosure();
  const [dataProviderInfo, setDataProviderInfo] = useState<
    GetPartnerDataProviderInfoResponseDTO | undefined
  >(undefined);
  const [profit, setProfit] = useState<GetDataProviderProfitsResponseDTO>();
  const [pageIndex, setPageIndex] = useState(0);
  const [profitRows, setProfitRows] = useState<ProfitRow[] | undefined>();
  const [formFieldUpdate, setFormFieldUpdate] = useState(false);
  const [selectedMkpProfitId, setSelectedMkpProfitId] = useState<string>();
  const toast = useToast();

  const isDomestic = useUserStore.getState().residence === 'domestic';

  const settlementMonthDetailModal = useDisclosure();
  const settlementTotalDetailModal = useDisclosure();
  const paymentSummaryModal = useDisclosure();
  const previousMonth = getPreviousMonthStartAndEnd({
    date: new Date(),
    language: i18n.language,
    separator: i18n.language === 'ko' ? ' ' : '-',
  });

  const { setSettle } = useSettleStore(
    useShallow(state => ({
      setSettle: state.setSettle,
    })),
  );

  const [monthDetailModalInfo, setMonthDetailModalInfo] = useState<{
    title: string;
    detailValues: CharacterProfit[];
    period: string;
    totalRevenue: number;
    isDomestic: boolean;
  }>({
    title: t('table.current-month-revenue'),
    period: '',
    detailValues: [],
    totalRevenue: 0,
    isDomestic: true,
  });
  const [totalDetailModalInfo, setTotalDetailModalInfo] = useState<{
    title: string;
    detailValues?: CharacterProfit[];
    period: string;
    totalRevenue: number;
    totalWithDrawalRevenue: number;
    isDomestic: boolean;
  }>({
    title: t('table.total-accumulated-revenue'),
    period: (i18n.language === 'ko' ? '~' : '-') + ' ' + previousMonth.endDate,
    totalRevenue: 0,
    totalWithDrawalRevenue: 0,
    isDomestic: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const providerInfoRes = await requestPartnerGetDataProviderInfo();
      if (isSuccess(providerInfoRes)) {
        setDataProviderInfo(providerInfoRes.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!profit) return;

    const fetchData = async () => {
      const providerProfitsTotalRes =
        await requestGetDataProviderProfitsTotal();
      if (isSuccess(providerProfitsTotalRes)) {
        const isDomestic = !!(profit?.residence_type === 'domestic');
        const totalRevenue =
          (profit &&
            providerProfitsTotalRes.data.character_profit_row.reduce(
              (acc, curr) =>
                acc +
                (isDomestic ? curr.profit_amount_krw : curr.profit_amount_usd),
              0,
            )) ??
          0;

        setTotalDetailModalInfo({
          ...totalDetailModalInfo,
          ...{
            detailValues:
              providerProfitsTotalRes.data.character_profit_row ?? [],
            totalRevenue: totalRevenue,
            totalWithDrawalRevenue: isDomestic
              ? providerProfitsTotalRes.data.total_withdrawal_amount
              : providerProfitsTotalRes.data.total_withdrawal_amount_usd,
            isDomestic: isDomestic,
          },
        });
      }
    };
    fetchData();
  }, [profit]);

  useEffect(() => {
    const fetchData = async () => {
      setProfitRows(undefined);
      const providerProfitsRes = await requestGetDataProviderProfits({
        limit: pageLimit,
        page: pageIndex + 1,
      });

      if (isSuccess(providerProfitsRes)) {
        if (!profit) {
          setProfit(providerProfitsRes.data);
        }
        setProfitRows(providerProfitsRes.data.profit_rows.data);
      }
      if (isFailed(providerProfitsRes)) {
        toast({
          position: 'top',
          title: 'Error',
          description: providerProfitsRes.error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [pageIndex]);

  useEffect(() => {
    const getBankAccount = async () => {
      if (!dataProviderInfo?.id) return;

      const providerId = dataProviderInfo?.id ?? '';
      try {
        const bankAccountRes = await requestGetBankAccount({
          providerId: providerId,
        });

        if (isSuccess(bankAccountRes)) {
          const {
            tax_return_address,
            account_holder_name,
            account_bank_code,
            account_number,
            account_copy_of_bankbook_filename,
            tax_return_name,
            tax_return_rrn,
            tax_return_phone_number,
            tax_return_email_address,
            tax_return_copy_of_id_card_filename,
          } = bankAccountRes.data;
          const { address, detail } = parseAddress(tax_return_address);

          const [bankbookRes, idCardRes] = await Promise.all([
            requestGetBankAccountBankbookImage({
              providerId: providerId,
              fileName: account_copy_of_bankbook_filename,
            }),
            requestGetBankAccountIdCardImage({
              providerId: providerId,
              fileName: tax_return_copy_of_id_card_filename,
            }),
          ]);

          if (isSuccess(bankbookRes) && isSuccess(idCardRes)) {
            setSettle({
              accountOwner: account_holder_name,
              bank: account_bank_code,
              accountNumber: {
                number: account_number,
                confirmNumber: account_number,
              },
              bankBook: [bankbookRes.data],
              name: tax_return_name,
              ssn: {
                front: tax_return_rrn.slice(0, 6),
                back: tax_return_rrn.slice(6, 13),
              },
              address: address,
              detailAddress: detail,
              isKoreanResident: true,
              phoneNumber: tax_return_phone_number,
              email: tax_return_email_address,
              isUserResponsible: true,
              residenceCard: [idCardRes.data],
            });
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (profit?.has_payout_info && dataProviderInfo?.id) {
      (async () => {
        setFormFieldUpdate(true);
        await getBankAccount();
        setFormFieldUpdate(false);
      })();
    }
  }, [profit, dataProviderInfo]);

  const onClickPrev = (index: number) => {
    if (index >= MAX_INDEX)
      setPageIndex(index => Math.floor(index / MAX_INDEX) * MAX_INDEX - 1);
  };

  const onClickPage = (num: number) => {
    setPageIndex(num - 1);
  };

  const onClickNext = (index: number, quotient: number) => {
    if (!(quotient <= index))
      setPageIndex(index => Math.ceil((index + 1) / MAX_INDEX) * MAX_INDEX);
  };

  const onClickMonthRevenue = (
    date: Period,
    item: CharacterProfit[],
    totalRevenue: number,
    isDomestic: boolean,
  ) => {
    setMonthDetailModalInfo({
      title: monthDetailModalInfo.title,
      period: date.startDate + ' ~ ' + date.endDate,
      detailValues: item,
      totalRevenue: totalRevenue,
      isDomestic: isDomestic,
    });

    settlementMonthDetailModal.onOpen();
  };

  const onClickTotalRevenue = () => {
    settlementTotalDetailModal.onOpen();
  };

  const onClickSettlementRevenue = (mkpProfitId: string) => {
    paymentSummaryModal.onOpen();
    setSelectedMkpProfitId(mkpProfitId);
  };

  return (
    <>
      <Flex flexDirection="column" rowGap={6}>
        <SettlementTitle isDomestic={isDomestic} />
        {profit ? (
          <>
            {dataProviderInfo?.id && isDomestic && (
              <SettlementStatus
                isLoading={formFieldUpdate}
                isFormComplete={profit.has_payout_info}
                onOpenSettlementInputModal={settlementInputModal.onOpen}
              />
            )}
            <UnsettlementRevenue
              isLoading={formFieldUpdate}
              isFormComplete={profit.has_payout_info}
              feedback={profit.payout_issue}
              revenue={profit.balance}
              providerId={dataProviderInfo?.id}
              isDomestic={profit.residence_type === 'domestic'}
              onOpenSettlementInputModal={settlementInputModal.onOpen}
            />
            <Flex
              flexDirection="column"
              p={6}
              w="full"
              bgColor="grey.100"
              borderRadius={10}
              rowGap={6}
            >
              <SettlementTable
                profit={profit}
                profitRows={profitRows}
                pageIndex={pageIndex}
                pageLimit={pageLimit}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                onClickPage={onClickPage}
                onClickMonthRevenue={onClickMonthRevenue}
                onClickSettlementRevenue={onClickSettlementRevenue}
              />
              <SettlementTotalRevenueBoxContainer
                totalRevenue={{
                  totalAccumulatedRevenue: totalDetailModalInfo.totalRevenue,
                  totalSettledRevenue:
                    totalDetailModalInfo.totalWithDrawalRevenue,
                }}
                isDomestic={isDomestic}
                onClickTotalRevenue={onClickTotalRevenue}
              />
            </Flex>
          </>
        ) : (
          <SettlementSkeleton />
        )}
      </Flex>
      {settlementInputModal.isOpen && dataProviderInfo?.id && (
        <SettlementInputModal
          dataProviderId={dataProviderInfo.id}
          isFormComplete={profit?.has_payout_info ?? false}
          isOpen={settlementInputModal.isOpen}
          onClose={settlementInputModal.onClose}
        />
      )}
      {settlementMonthDetailModal.isOpen && (
        <SettlementDetailModal
          detailValues={monthDetailModalInfo.detailValues}
          totalRevenue={monthDetailModalInfo.totalRevenue}
          period={monthDetailModalInfo.period}
          isDomestic={monthDetailModalInfo.isDomestic}
          title={monthDetailModalInfo.title}
          isOpen={settlementMonthDetailModal.isOpen}
          onClose={settlementMonthDetailModal.onClose}
        />
      )}
      {settlementTotalDetailModal.isOpen && (
        <SettlementDetailModal
          detailValues={totalDetailModalInfo.detailValues}
          totalRevenue={totalDetailModalInfo.totalRevenue}
          isDomestic={totalDetailModalInfo.isDomestic}
          period={totalDetailModalInfo.period}
          title={totalDetailModalInfo.title}
          isOpen={settlementTotalDetailModal.isOpen}
          onClose={settlementTotalDetailModal.onClose}
        />
      )}
      {paymentSummaryModal.isOpen && selectedMkpProfitId && (
        <PaymentSummaryModal
          isOpen={paymentSummaryModal.isOpen}
          onClose={paymentSummaryModal.onClose}
          mkpProfitId={selectedMkpProfitId}
        />
      )}
    </>
  );
}

function SettlementSkeleton() {
  return (
    <>
      <Skeleton width="100%" height="82px" borderRadius={10} />
      <Skeleton width="100%" height="159px" borderRadius={10} />
      <Skeleton width="100%" height="376px" borderRadius={10} />
    </>
  );
}
