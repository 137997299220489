import { TDS } from '@/libs/chakra';
import { TypoProps } from '@/libs/chakra/components/Typo';
import { cn } from '@/utils';
import { formatToKRW, formatToUSD } from '@/utils/format-currency';

export function SettlementRevenue({
  revenue,
  onClickDetail,
  isDomestic = true,
  disabled,
  icon,
  ...props
}: TypoProps & {
  onClickDetail: () => void;
  revenue: number;
  isDomestic: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
}) {
  const onClickTypo = () => {
    onClickDetail();
  };

  return (
    <TDS.Typo
      as="span"
      className={cn('underline-under', disabled && 'pointer-events-none')}
      cursor="pointer"
      textDecorationLine={disabled ? 'none' : 'underline'}
      onClick={onClickTypo}
      {...props}
    >
      {isDomestic ? formatToKRW(revenue) : formatToUSD(revenue)}
      {icon}
    </TDS.Typo>
  );
}
