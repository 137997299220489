import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToast,
  TDS,
} from '@/components/ui';
import { VOICEHUB_GUIDE_LINK } from '@/constants/external-link';
import { INQUIRY_EMAIL } from '@/constants/help';
import { useUserStore } from '@/stores/user';
import { copyTextToClipboard } from '@/utils/clipboard';
import { cn } from '@/utils/cn';

export function HelpFloatingButton({
  className,
  ...props
}: Omit<ComponentProps<'button'>, 'children'>) {
  const { t } = useTranslation('common');
  const toast = useToast();
  const isDomestic = useUserStore(state => state.residence === 'domestic');

  const handleInquiryClick = () => {
    copyTextToClipboard(INQUIRY_EMAIL, () => {
      toast({
        position: 'top',
        render: () => (
          <div className="flex cursor-pointer justify-center gap-3 rounded-xl bg-grey-900 px-4 py-3 ">
            <i className="i-t-alert text-6 text-white" />
            <TDS.Typo color="white" size="md">
              이메일 주소가 클립보드에 복사되었습니다.
            </TDS.Typo>
          </div>
        ),
      });
    });
  };

  return (
    <TDS.Popover isLazy placement="top-end">
      <PopoverTrigger>
        <button
          className={cn(
            'rounded-full w-10 h-10 bg-white hover:bg-grey-50 active:bg-grey-100 border border-grey-200',
            className,
          )}
          {...props}
        >
          ?
        </button>
      </PopoverTrigger>
      <PopoverContent w={180} borderRadius={16}>
        <PopoverBody py={3} px={4}>
          {isDomestic && (
            <TDS.Button
              as="a"
              href={VOICEHUB_GUIDE_LINK}
              target="_blank"
              variant="solid"
              colorScheme="grey"
              size="md"
              w="full"
              justifyContent="flex-start"
              leftIcon={<i className="i-t-file-book text-6" />}
            >
              {t('help-floating-button.how')}
            </TDS.Button>
          )}
          <div className="py-2 mt-0.5">
            <TDS.Typo
              as="p"
              size="sm"
              weight="medium"
              className="color-grey-600"
            >
              {t('help-floating-button.help-desk')}:
              <br />
            </TDS.Typo>
            <TDS.Typo
              as="p"
              tabIndex={0}
              className="underline underline-offset-2.5 color-grey-600 cursor-pointer"
              onClick={handleInquiryClick}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleInquiryClick();
                }
              }}
              size="xs"
            >
              {INQUIRY_EMAIL}
            </TDS.Typo>
            <TDS.Typo
              as="p"
              size="sm"
              weight="medium"
              className="color-grey-600 mt-1"
            >
              {t('help-floating-button.working-hours-label')}:
            </TDS.Typo>
            <TDS.Typo
              as="p"
              tabIndex={0}
              className=" color-grey-600 whitespace-pre-line"
              size="xs"
            >
              {t('help-floating-button.working-hours-detail')}
            </TDS.Typo>
          </div>
        </PopoverBody>
      </PopoverContent>
    </TDS.Popover>
  );
}
