import { AxiosError } from 'axios';

import { HttpClient } from '@/libs/http-client';
import { Success, Failed } from '@/types/result';
import { Role } from '@/types/role';

import type {
  GetBankAccountBankbookImageRequestDTO,
  GetBankAccountBankbookImageResponseDTO,
  GetBankAccountIdCardImageRequestDTO,
  GetBankAccountIdCardImageResponseDTO,
  GetBankAccountRequestDTO,
  GetBankAccountResponseDTO,
  GetPartnerDataProviderInfoResponseDTO,
  GetDataProviderProfitsRequestDTO,
  GetDataProviderProfitsResponseDTO,
  GetDataProviderProfitsTotalResponseDTO,
  GetPaydayRoleResponseDTO,
  UpdatePaydayRoleResponseDTO,
  GetDataProviderInfoResponseDTO,
  GetDataProviderDetailProfitsResponseDTO,
} from './types/payday';

/**
 * TODO: AxiosError를 반환하도록 수정할 것
 */
const baseURL = import.meta.env.VITE_PAYDAY_API_URL;
const httpClient = new HttpClient({ baseURL });

export const requestGetPaydayRole = async () => {
  try {
    const res = await httpClient.request<GetPaydayRoleResponseDTO>({
      url: '/api/v1/accounts',
      method: 'get',
    });

    return {
      isFailed: false,
      data: res.data.role,
    } as Success<Role[]>;
  } catch (err) {
    return {
      isFailed: true,
      error: err as AxiosError,
    } as Failed<AxiosError>;
  }
};

export const requestUpdatePaydayRole = async () => {
  try {
    const res = await httpClient.request<UpdatePaydayRoleResponseDTO>({
      url: '/api/v1/marketplace/data-providers/registration',
      method: 'get',
    });

    return {
      isFailed: false,
      data: res.data.role,
    } as Success<Role[]>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as AxiosError,
    } as Failed<AxiosError>;
  }
};

export async function requestUpdateSettlement(payload: {
  dataProviderId: string;
  accountOwner: string;
  bank: string;
  accountNumber: string;
  bankBook: File;
  ssnFront: string;
  ssnBack: string;
  name: string;
  address: string;
  detailAddress: string;
  residenceCard: File;
  phoneNumber: string;
  email: string;
}) {
  const {
    dataProviderId,
    accountOwner,
    bank,
    accountNumber,
    bankBook,
    ssnFront,
    ssnBack,
    name,
    address,
    detailAddress,
    residenceCard,
    phoneNumber,
    email,
  } = payload;

  try {
    const formData = new FormData();
    formData.append('account_copy_of_bankbook', bankBook);
    formData.append('account_holder_name', accountOwner);
    formData.append('account_number', accountNumber);
    formData.append('account_bank_code', bank);
    formData.append('tax_return_copy_of_id_card', residenceCard);
    formData.append('tax_return_name', name);
    formData.append('tax_return_rrn', ssnFront + ssnBack);
    formData.append('tax_return_address', address + '+' + detailAddress);
    formData.append('tax_return_phone_number', phoneNumber);
    formData.append('tax_return_email_address', email);

    await httpClient.request(
      {
        url: `/api/v1/partners/data-providers/${dataProviderId}/payout-methods/bank-account`,
        method: 'post',
      },
      formData,
    );

    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestPartnerGetDataProviderInfo() {
  try {
    const res = await httpClient.request<GetPartnerDataProviderInfoResponseDTO>(
      {
        url: '/api/v1/partners/data-providers/info',
        method: 'get',
      },
    );

    return {
      isFailed: false,
      data: res.data,
    } as Success<GetPartnerDataProviderInfoResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetDataProviderProfits(
  payload: GetDataProviderProfitsRequestDTO,
) {
  const { page, limit } = payload;
  try {
    const res = await httpClient.request<GetDataProviderProfitsResponseDTO>(
      {
        url: '/api/v1/marketplace/data-providers/profits',
        method: 'get',
      },
      { page, limit },
    );

    return {
      isFailed: false,
      data: res.data,
    } as Success<GetDataProviderProfitsResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetDataProviderDetailProfits(payload: string) {
  try {
    const res =
      await httpClient.request<GetDataProviderDetailProfitsResponseDTO>({
        url: `/api/v1/marketplace/data-providers/profits/${payload}`,
        method: 'get',
      });

    return {
      isFailed: false,
      data: res.data,
    } as Success<GetDataProviderDetailProfitsResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetDataProviderProfitsTotal() {
  try {
    // CharacterProfit와 GetDataProviderProfitsTotalResponse 타입은 같음.
    const res =
      await httpClient.request<GetDataProviderProfitsTotalResponseDTO>({
        url: '/api/v1/marketplace/data-providers/profits/total',
        method: 'get',
      });

    return {
      isFailed: false,
      data: res.data,
    } as Success<GetDataProviderProfitsTotalResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetBankAccount(payload: GetBankAccountRequestDTO) {
  try {
    const res = await httpClient.request<GetBankAccountResponseDTO>({
      url: `/api/v1/partners/data-providers/${payload.providerId}/payout-methods/bank-account`,
      method: 'get',
    });

    return {
      isFailed: false,
      data: res.data,
    } as Success<GetBankAccountResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetBankAccountIdCardImage(
  payload: GetBankAccountIdCardImageRequestDTO,
) {
  try {
    const res = await httpClient.request<GetBankAccountIdCardImageResponseDTO>({
      url: `/api/v1/partners/data-providers/${payload.providerId}/payout-methods/bank-account/id-card-image`,
      method: 'get',
      responseType: 'blob',
    });

    const file = new File([res.data], payload.fileName, {
      type: 'image/png',
    });

    return {
      isFailed: false,
      data: file,
    } as Success<GetBankAccountIdCardImageResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetBankAccountBankbookImage(
  payload: GetBankAccountBankbookImageRequestDTO,
) {
  try {
    const res =
      await httpClient.request<GetBankAccountBankbookImageResponseDTO>({
        url: `/api/v1/partners/data-providers/${payload.providerId}/payout-methods/bank-account/bankbook-image`,
        method: 'get',
        responseType: 'blob',
      });

    const file = new File([res.data], payload.fileName, {
      type: 'image/png',
    });

    return {
      isFailed: false,
      data: file,
    } as Success<GetBankAccountBankbookImageResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetDataProviderInfo() {
  try {
    const res = await httpClient.request<GetDataProviderInfoResponseDTO>({
      url: '/api/v1/marketplace/data-providers/info',
      method: 'get',
    });

    return {
      isFailed: false,
      data: res.data,
    } as Success<GetDataProviderInfoResponseDTO>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}
